import React from 'react';
import CartPage from 'rev.sdk.js/Templates/CartPage';
import withPageEntry from '../../withPageEntry';
import * as CustomCheckout from '../../Utils/CustomCheckout';

function Cart(props) {
  return <CartPage {...props} {...CustomCheckout.getExtraCartPageProps()} />;
}

export default withPageEntry(Cart);
